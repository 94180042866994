import React, { useState } from 'react';
import {
  DateText,
  IsReadIcon,
  MessageBlock,
  MessageBottomInfoBlock,
  MessageBottomLeftInfoBlock,
  MessageBottomRightInfoBlock,
  MessageSuperBlock,
  UnReadIcon,
  UserNameText,
  ButtonsContainer,
  MessageTextArea,
  Title,
} from './style';

import { IChatMessage } from '@/types';
import { formatTime } from '@/utils/format/datetime';
import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import Button from '@/common/core/Button';
import { sendReminderMessage, rejectReminderMessage } from '@/api/reminders';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from './style';

interface IChatMessagesListSendReminder {
  userIdFrom: string;
  userNameFrom: string;
  currentUserId: string;
  createdAtSeconds: number;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  isViewed: boolean;
  messageBy: string;
  message: IChatMessage;
  onSend?: (text: string) => void;
  onReject?: () => void;
}

type DialogConfig = {
  title: string;
  content: string;
  confirmLabel: string;
  confirmColor: 'primary' | 'error';
  onConfirm: () => void | Promise<void>;
};

const ChatMessagesListSendReminder = ({
  userIdFrom,
  currentUserId,
  userNameFrom,
  createdAtSeconds,
  toggleMenu,
  anchorEl,
  isViewed,
  messageBy,
  message,
}: IChatMessagesListSendReminder) => {
  const [text, setText] = useState(message.body);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);
  const [isCompleted, setIsCompleted] = useState(message.isCompleted || false);
  const [isDeclined, setIsDeclined] = useState(message.isDeclined || false);

  const handleSend = async () => {
    if (text.trim()) {
      try {
        await sendReminderMessage({
          ...message,
          body: text,
        });
        setIsCompleted(true);
      } catch (error) {
        console.error('Error sending reminder message:', error);
      }
    }
    setDialogOpen(false);
  };

  const handleReject = async () => {
    try {
      await rejectReminderMessage({
        ...message,
        body: text,
      });
      setIsDeclined(true);
    } catch (error) {
      console.error('Error rejecting reminder message:', error);
    }
    setDialogOpen(false);
  };

  const openConfirmDialog = (config: DialogConfig) => {
    setDialogConfig(config);
    setDialogOpen(true);
  };

  return (
    <MessageSuperBlock>
      <MessageBlock
        isCurrentUser={userIdFrom === currentUserId}
        anchorEl={anchorEl}
        onClick={toggleMenu}
      >
        <Title>Send a reminder</Title>
        <>
          <MessageTextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            disabled={isCompleted || isDeclined}
          />
          {!isCompleted && !isDeclined && (
            <ButtonsContainer>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  openConfirmDialog({
                    title: 'Send Reminder',
                    content: 'Are you sure you want to send this reminder message?',
                    confirmLabel: 'Send',
                    confirmColor: 'primary',
                    onConfirm: handleSend,
                  })
                }
                disabled={isCompleted || isDeclined}
              >
                Send
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  openConfirmDialog({
                    title: 'Reject Reminder',
                    content: 'Are you sure you want to reject this reminder message?',
                    confirmLabel: 'Reject',
                    confirmColor: 'error',
                    onConfirm: handleReject,
                  })
                }
                disabled={isCompleted || isDeclined}
              >
                Reject
              </Button>
            </ButtonsContainer>
          )}
        </>

        {isCompleted && (
          <ButtonsContainer>
            <Button color="primary" disabled fullWidth>
              Reminder sent
            </Button>
          </ButtonsContainer>
        )}

        {isDeclined && (
          <ButtonsContainer>
            <Button color="error" disabled fullWidth>
              Reminder ignored
            </Button>
          </ButtonsContainer>
        )}

        <MessageBottomInfoBlock>
          <MessageBottomLeftInfoBlock>
            <UserNameText isCurrentUser={userIdFrom === currentUserId}>{messageBy}</UserNameText>
          </MessageBottomLeftInfoBlock>
          <MessageBottomRightInfoBlock>
            <DateText isCurrentUser={userIdFrom === currentUserId}>
              {formatTime(createdAtSeconds * 1000)}
            </DateText>
            {userIdFrom !== currentUserId && (
              <>{isViewed ? <IsReadIcon as={DoneAllIcon} /> : <UnReadIcon as={DoneIcon} />}</>
            )}
          </MessageBottomRightInfoBlock>
        </MessageBottomInfoBlock>
      </MessageBlock>

      {dialogConfig && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">{dialogConfig.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              {dialogConfig.content}
            </DialogContentText>
          </DialogContent>
          <DialogActionsStyled>
            <Button
              onClick={dialogConfig.onConfirm}
              color="primary" // Keep primary color for main action
              variant="contained"
              autoFocus
            >
              {dialogConfig.confirmLabel}
            </Button>
            <Button
              onClick={() => setDialogOpen(false)}
              color="error" // Changed to error (red)
            >
              Cancel
            </Button>
          </DialogActionsStyled>
        </Dialog>
      )}
    </MessageSuperBlock>
  );
};

export default ChatMessagesListSendReminder;
